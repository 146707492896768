import IHeading from "@/components/IHeading"
import ILine from "@/components/ILine"
import IParagraph from "@/components/IParagraph"
import Layout from "@/components/Layout"
import PageHeading from "@/components/PageHeading"
import PageIntro from "@/components/PageIntro"
import Seo from "@/components/seo"
import { graphql, Link, PageProps } from "gatsby"
import * as React from "react"
import img1 from "../images/vectors/undraw_Maintenance_re_59vn.svg"
import img2 from "../images/vectors/undraw_Thought_process_re_om58.svg"
import img3 from "../images/vectors/undraw_Development_re_g5hq.svg"
import img6 from "../images/vectors/undraw_server_cluster_jwwq.svg"
import img5 from "../images/vectors/undraw_monitor_iqpq.svg"
import img4 from "../images/vectors/undraw_connected_world_wuay.svg"
import { DeviceHub } from "@material-ui/icons"
import { Weekend } from "@material-ui/icons"
import { Brush } from "@material-ui/icons"
import { Fastfood } from "@material-ui/icons"
import { ShoppingBasket } from "@material-ui/icons"
import { StaticImage } from "gatsby-plugin-image"
import { PeopleOutline } from "@material-ui/icons"
import { Timeline } from "@material-ui/icons"
import Testimonial from "@/components/Testimonial"
import FAQs from "@/components/FAQs"
import CallToAction from "@/components/CallToAction"

interface UiUxData {
  mdx: {
    body: string
    excerpt: string
    frontmatter: {
      title: string
      heading: string
      iconName: string
      color?: string
    }
  }
}

const UiUxPage: React.FC<PageProps<UiUxData>> = ({ data }) => {
  const { frontmatter, excerpt } = data.mdx
  const { title, heading } = frontmatter
  const feature = [
    {
      title: "User Interface (UI) Design",
    },
    {
      title: " User Experience (UX) Design",
    },
    {
      title: "Responsive Web Design",
    },
    {
      title: "Mobile App Design",
    },
    {
      title: "Conversion Rate Optimization (CRO)",
    },
    {
      title: "Usability Testing",
    },
    {
      title: "Information Architecture",
    },
    {
      title: "Interaction Design",
    },
  ]
  const Process = [
    {
      title: "Discovery and Planning",
      content:
        "In-depth analysis of your brand and target audience. Competitor research and market trends evaluation. User persona development and journey mapping. Project scope definition and timeline creation.",
    },
    {
      title: "Design and Development",
      content:
        "Wireframing and prototyping. Visual design and branding integration. Interactive element creation. Responsive design implementation.",
    },
    {
      title: "Testing and Quality Assurance",
      content:
        "Usability testing with target user groups. A/B testing for key design elements. Cross-browser and device compatibility checks. Performance optimization.",
    },
    {
      title: "Launch and Post-launch Support",
      content:
        "Seamless deployment of new design. User behavior monitoring and analytics setup. Continuous improvement based on user feedback. Ongoing support and maintenance.",
    },
  ]
  const Core = [
    {
      title: "Custom Ecommerce Development",
      content:
        "We build bespoke ecommerce solutions tailored to your unique business needs, ensuring a perfect fit for your products and customers.",
      imgSrc: img1,
    },
    {
      title: "Platform Migration",
      content:
        "Seamlessly transition your store to a new platform without losing data or disrupting sales.",
      imgSrc: img2,
    },
    {
      title: "Third-party Integrations",
      content:
        "Enhance your store's functionality with seamless integrations for payment gateways, inventory management, CRM systems, and more.",
      imgSrc: img3,
    },
    {
      title: " Performance Optimization",
      content:
        "Boost your site's speed and efficiency to improve user experience and search engine rankings",
      imgSrc: img6,
    },
    {
      title: " Security Enhancements",
      content:
        "Implement robust security measures to protect your customers' data and build trust.",
      imgSrc: img5,
    },
    {
      title: " Mobile Commerce Solutions",
      content:
        "Create responsive designs or dedicated mobile apps to capture the growing mobile shopping market.",
      imgSrc: img4,
    },
  ]
  const helpItems = [
    {
      icon: <ShoppingBasket />,
      content: "Fashion and Apparel",
    },
    {
      icon: <DeviceHub />,
      content: " Electronics and Gadgets",
    },
    {
      icon: <Weekend />,
      content: " Home and Garden",
    },
    {
      icon: <Brush />,
      content: "Beauty and Personal Care",
    },
    {
      icon: <Fastfood />,
      content: "Food and Beverage",
    },
  ]
  const items = [
    {
      imgSrc: <PeopleOutline />,
      title: "Team Expertise",
      desc: " Our team of seasoned UI/UX designers, developers, and ecommerce specialists brings years of experience and a passion for creating exceptional online shopping experiences.",
    },
    {
      imgSrc: <Timeline />,
      title: "Proven Track Record",
      desc: "We've helped numerous ecommerce businesses increase their conversions, user engagement, and overall satisfaction through our innovative design solutions.",
    }
  ]
  const faqData = [
    {
      question: "How long does a typical UI/UX redesign project take?",
      answer: "Project timelines vary based on complexity, but most redesigns take 8-12 weeks from discovery to launch.",
    },
    {
      question: "Will my store need to go offline during the redesign?",
      answer: "No, we implement changes in a staging environment and only go live once everything is tested and approved.",
    },
    {
      question: "How do you ensure the new design will improve conversions?",
      answer: "We use data-driven design principles, conduct thorough user testing, and implement A/B testing to optimize for conversions.",
    },
    {
      question: "Can you work with our existing branding guidelines?",
      answer: "Absolutely! We'll seamlessly integrate your brand identity into the new design while enhancing user experience.",
    },
    {
      question: "Do you offer ongoing support after the redesign is complete?",
      answer: "Yes, we provide post-launch support and can set up maintenance plans to ensure your store continues to perform optimally.",
    },
  ];
  
  return (
    <>
      <Layout>
        <Seo title="UI/UX Design Solutions for Ecommerce Excellence"
        description="Elevate your ecommerce experience with our expert UI/UX design solutions. From intuitive navigation to seamless checkouts, we craft designs that boost conversions and customer loyalty." />
        <div data-aos="fade-up">
          <PageHeading title={title} description={heading}></PageHeading>
        </div>
        <div data-aos="fade-up">
          <PageIntro>{excerpt}</PageIntro>
        </div>
        <section className="w-full my-20">
          <div className="container mx-auto">
            <div className="max-w-5xl" data-aos="fade-up">
              <IParagraph>
                At Easesol, we understand that exceptional UI/UX design is the
                cornerstone of successful ecommerce. Our approach combines
                aesthetic appeal with functional efficiency, creating online
                shopping experiences that delight customers and boost your
                bottom line.
              </IParagraph>
            </div>
          </div>
        </section>

        <section className="w-full my-24">
          <div className="container mx-auto">
            <div className="w-full">
              <div>
                <div data-aos="fade-up">
                  <IHeading align="left">Range of Services Offered::</IHeading>
                </div>
              </div>
              <div className="grid grid-cols-1 py-10 -mx-1 overflow-hidden md:grid-cols-2 gap-x-10">
                {feature.map((item: any, index: number) => {
                  return (
                    <div
                      className="relative w-full px-6 my-6 overflow-hidden group"
                      key={index}
                      data-aos="fade-up"
                    >
                      <IHeading size="three">{item.title}</IHeading>
                      <IParagraph>{item.content}</IParagraph>
                      <div
                        className="absolute top-0 bottom-0 left-0 w-3 duration-300 bg-primary-200 dark:bg-primary-700 group-hover:w-full"
                        style={{ zIndex: "-1" }}
                      ></div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        <section className="w-full mt-24">
          <div className="container mx-auto work-flow-container">
            <div className="mb-2" data-aos="fade-up">
              <IHeading size="subTitle">Our Process</IHeading>
            </div>
            <ILine align="left" />
            <div className="grid w-full grid-cols-1 gap-16 py-16 mx-auto work-flow-box md:grid-cols-2 2xl:grid-cols-3 md:gap-10">
              {Process.map((item: any, index: number) => {
                return (
                  <div
                    className="relative flex flex-col items-center justify-center p-16 text-3xl text-center text-white shadow-xl group work-flow-item rounded-xl"
                    data-aos="fade-up"
                  >
                    {item.title}
                    <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center px-4 py-6 text-base text-center duration-300 shadow-2xl opacity-0 rounded-xl bg-primary-50 dark:bg-primary-200 text-primary-500 dark:text-primary-600 lg:text-lg group-hover:opacity-100">
                      {item.content}
                    </div>
                    <div className="absolute left-0 right-0 flex items-center justify-center mx-auto text-3xl text-center border-4 border-white rounded-full w-14 h-14 bg-primary-700 -top-8 md:-top-5 md:right-auto md:-left-5">
                      {index + 1}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="w-full my-16">
          <div className="container pb-8 mx-auto">
            <div data-aos="fade-up">
              <IHeading align="left">Core Services</IHeading>

              <div className="">
                <ILine align="left" />
              </div>
            </div>

            {Core.map((item: any, index: number) => {
              return (
                <>
                  <div
                    className={`flex flex-col ${
                      index % 2 === 1 ? "lg:flex-row-reverse" : "lg:flex-row"
                    } items-center mb-20 gap-x-5 md:gap-x-10 xl:gap-x-20 gap-y-10`}
                    key={index}
                  >
                    <div className="w-full lg:w-1/2">
                      <div data-aos="fade-up">
                        <IHeading size="two">{item.title}</IHeading>
                      </div>
                      <div data-aos="fade-up">
                        <IParagraph>{item.content}</IParagraph>
                      </div>
                    </div>
                    <div
                      className="w-full max-w-lg lg:w-1/2 lg:max-w-max"
                      data-aos="fade-up"
                    >
                      <img src={item.imgSrc} alt={item.title} />
                    </div>
                  </div>
                </>
              )
            })}
          </div>
        </section>
        <section className="w-full my-16">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center">Industry Expertise</IHeading>
            </div>
            <div data-aos="fade-up">
              <IParagraph align="text-center">
                We've successfully delivered UI/UX design solutions across
                various ecommerce sectors, including:
              </IParagraph>
            </div>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-2 mt-14">
              {helpItems.map((item: any, index: number) => {
                return (
                  <div
                    className="relative flex flex-col items-center p-10 overflow-hidden border-b-2 rounded-md shadow-xl group border-slate-600 dark:border-primary-400 dark:bg-primary-900"
                    key={index}
                    data-aos="fade-up"
                  >
                    <div className="z-10 mb-6 startup-help-icon-box">
                      {item.icon}
                    </div>
                    <p className="absolute text-2xl lg:text-3xl  items-center text-center top-0 bottom-0 left-0 right-0 z-0 p-16  text-gray-200 duration-300 opacity-0 bg-slate-600 dark:bg-primary-600 group-hover:opacity-100 group-hover:z-20">
                      {item.content}
                    </p>
                  </div>
                )
              })}
            </div>
            <div className="py-12">
              <div data-aos="fade-up">
                <IHeading align="text-left" size="subTitle">
                  Success Story: FashionForward
                </IHeading>
              </div>
              <div data-aos="fade-up">
                <IParagraph align="left">
                  We redesigned the UI/UX for FashionForward, a trendy online
                  clothing store. The new design resulted in:
                </IParagraph>
              </div>
              <div className="grid grid-cols-3">
              <div
                className="relative w-full px-6 my-6 overflow-hidden group"
                data-aos="fade-up"
              >
                <IHeading size="three">
                  40% increase in conversion rate
                </IHeading>
                <div
                  className="absolute top-0 bottom-0 left-0 w-3 duration-300 bg-primary-200 dark:bg-primary-700 group-hover:w-full"
                  style={{ zIndex: "-1" }}
                ></div>
              </div>
              <div
                className="relative w-full px-6 my-6 overflow-hidden group"
                data-aos="fade-up"
              >
                <IHeading size="three">
                  25% reduction in cart abandonment
                </IHeading>
                <div
                  className="absolute top-0 bottom-0 left-0 w-3 duration-300 bg-primary-200 dark:bg-primary-700 group-hover:w-full"
                  style={{ zIndex: "-1" }}
                ></div>
              </div>
              <div
                className="relative w-full px-6 my-6 overflow-hidden group"
                data-aos="fade-up"
              >
                <IHeading size="three">50% increase in mobile sales</IHeading>
                <div
                  className="absolute top-0 bottom-0 left-0 w-3 duration-300 bg-primary-200 dark:bg-primary-700 group-hover:w-full"
                  style={{ zIndex: "-1" }}
                ></div>
              </div>
              </div>
              
              <div className="">
                <ILine align="left" />
              </div>
            </div>
          </div>
        </section>
        <section className="py-16 my-14 bg-primary-200 dark:bg-primary-600  rounded-xl">
          <div className="container mx-auto">
            <div className="max-w-2xl mx-auto" data-aos="fade-up">
              <IHeading align="text-center">Technology Partners</IHeading>
              <IParagraph align="text-center">
              We work with leading ecommerce platforms to deliver exceptional results:
              </IParagraph>
            </div>
            <ILine align="center" />

            <div
              className="flex justify-around flex-wrap md:flex-nowrap md:justify-evenly gap-4 items-center space-y-2.5 pt-4 pb-8"
              data-aos="fade-up"
            >
              <div className="techstack-icon-box group item">
                <Link to="https://www.shopify.com/" target="_blank">
                  <StaticImage
                    src="../images/techs-icons/shopify.svg"
                    className="black-stack-icon duration-200 group-hover:scale-125 "
                    alt="Shopify"
                  />
                </Link>
              </div>
              <div className="techstack-icon-box group item w-26">
                <Link to="https://www.bigcommerce.com/" target="_blank">
                  <StaticImage
                    src="../images/techs-icons/bigcommerce.svg"
                    className="black-stack-icon pointer-events-none duration-200 group-hover:scale-125 block dark:hidden"
                    alt="BigCommerce"
                  />
                </Link>
              </div>
              <div className="techstack-icon-box group item">
                <Link to="https://woocommerce.com/" target="_blank">
                  <StaticImage
                    src="../images/techs-icons/woocommerce.svg"
                    className="black-stack-icon pointer-events-none duration-200 group-hover:scale-125 block dark:hidden"
                    alt="WooCommerce"
                  />
                </Link>
              </div>
              <div className="techstack-icon-box group item">
                <Link
                  to="https://www.magentoassociation.org/home"
                  target="_blank"
                >
                  <StaticImage
                    src="../images/techs-icons/magento.svg"
                    className="black-stack-icon pointer-events-none duration-200 group-hover:scale-125 block dark:hidden"
                    alt="Magento"
                  />
                </Link>
              </div>
            </div>
            
            <div data-aos="fade-up">
              <IParagraph align="text-center">
              We also leverage cutting-edge design tools like Figma, Adobe XD, and Sketch to bring your vision to life.

              </IParagraph>
            </div>
          </div>
        </section>
        <section className="w-full my-20">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center">Why Choose Us</IHeading>
            </div>
            <div className="grid grid-cols-1 mb-12 lg:grid-cols-2 gap-x-10 gap-y-28 mt-36">
              {items.map((item: any) => {
                return (
                  <div
                    className="flex flex-col items-center  p-5 rounded-lg shadow-xl bg-slate-700"
                    data-aos="fade-up"
                  >
                    <div className="flex items-center justify-center mb-6 -mt-20 overflow-hidden rounded-full icon-box w-28 md:w-40 h-28 md:h-40 bg-slate-200">
                      {item.imgSrc}
                    </div>
                    <IHeading size="subHeader" color="white">
                      {item.title}
                    </IHeading>
                    <div className="mt-3 mb-6">
                      <ILine />
                    </div>
                    <IParagraph color="gray">
                      <ul className="list-disc space-y-3 px-16">
                        {item.desc
                          .split(".")
                          .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                          .map(
                            (
                              sentence: string,
                              index: React.Key | null | undefined
                            ) => (
                              <li key={index}>{sentence.trim()}.</li>
                            )
                          )}
                      </ul>
                    </IParagraph>
                  </div>
                )
              })}
            </div>
            <div data-aos="fade-up">
              <IHeading align="text-left" size="subTitle">
                Client Testimonial
              </IHeading>
            </div>
            <Testimonial
              review="Easesol transformed our online store into a user-friendly, visually appealing platform that our customers love. Our sales have increased by 35% since the redesign!"
              name="Sarah Johnson"
              type="HomeStyle Decor"
              position="CEO"
              reviewLink="https://www.g2.com/products/posthog/reviews/posthog-review-4887279"
              reviewLinkText="Unknown"
            />
          </div>
        </section>
        <FAQs faqs={faqData} />
        <CallToAction
          title="Ready to Elevate Your Ecommerce Experience?"
          description="Don't let poor UI/UX design hold your online store back. Let our experts create a stunning, user-friendly ecommerce experience that will delight your customers and boost your sales."
          cta="Get Started Now"
          ctaTo="/contact-us"
        />
      </Layout>
    </>
  )
}

export const query = graphql`
  query UiUxPageQuery {
    mdx(slug: { eq: "ui-ux-solution" }) {
      body
      excerpt(pruneLength: 160)
      frontmatter {
        title
        heading
        iconName
        color
      }
    }
  }
`

export default UiUxPage
